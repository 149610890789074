import { useContext, useEffect, useReducer, useState } from 'react';
import { createBodyZone, deleteBodyZone, getBodyZoneList, updateBodyZone } from '../api/body-zone';
import { createMuscleTag, deleteMuscleTag, updateMuscleTag } from '../api/muscle-tag';
import { MuscleTagEditor } from '../components/MuscleTagEditor';
import { MuscleTagIdList } from '../components/MuscleTagIdList';
import { BodyZone, MuscleTag } from '../models/body-zone';
import { AppContext } from '../App';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

interface TagListState {
  muscleTagList: Array<MuscleTag>;
  muscleTagMap: Map<string, MuscleTag>;
}

interface TagAction {
  type: 'init' | 'add' | 'edit' | 'delete';
  payload: any;
}

const tagListReducer = (state: TagListState, action: TagAction): TagListState => {
  switch (action.type) {
    case 'init':
      return action.payload;
    case 'add':
      return { muscleTagList: [...state.muscleTagList, action.payload], muscleTagMap: state.muscleTagMap.set(action.payload.id, action.payload) };
    case 'edit':
      return {
        muscleTagList: state.muscleTagList.map((bzt) => (bzt.id === action.payload.id ? action.payload : bzt)),
        muscleTagMap: state.muscleTagMap?.set(action.payload.id, action.payload),
      };
    case 'delete':
      state.muscleTagMap.delete(action.payload.id);
      return { muscleTagList: state.muscleTagList.filter((bzt) => bzt.id !== action.payload.id), muscleTagMap: state.muscleTagMap };
    default:
      return state;
  }
};

const DeleteDialog = (props: { open: boolean; handleClose: (deleted: boolean) => void }) => {
  return (
    <Dialog open={props.open} onClose={() => props.handleClose(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{'Really delete this Tag?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Really delete this Tag?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose(false)}>Don't</Button>
        <Button onClick={() => props.handleClose(true)} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CannotDeleteTagWarning = (props: { open: boolean; setOpen: (open: boolean) => void }) => {
  return (
    <Dialog open={props.open} onClose={() => props.setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{'Cannot delete a Muscle Tag that is in Use'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          A Muscle Tag that is in use by a Body Zone cannot be deleted. Please edit the Body Zone and delete the Tag there first.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export const BodyZonePartManager = () => {
  const { bodyZoneList, refreshBodyZoneList } = useContext(AppContext);
  const { muscleTagList, muscleTagMap, refreshMuscleTagMap } = useContext(AppContext);
  const [state, dispatch] = useReducer(tagListReducer, { muscleTagList, muscleTagMap });
  const [newBodyZoneName, setNewBodyZoneName] = useState<string>();
  const [selectedTag, setSelectedTag] = useState<MuscleTag>();
  const [tagEditMode, setTagEditMode] = useState<boolean>(true);
  const [cannotDelete, setCannotDelete] = useState<boolean>(false);
  const [tagToDelete, setTagToDelete] = useState<MuscleTag | undefined>(undefined);

  useEffect(() => {
    dispatch({ type: 'init', payload: { muscleTagList: muscleTagList, muscleTagMap: muscleTagMap } });
  }, [muscleTagList, muscleTagMap]);

  return (
    <Container>
      <CannotDeleteTagWarning open={cannotDelete} setOpen={setCannotDelete} />
      <DeleteDialog
        open={tagToDelete !== undefined}
        handleClose={async (deleted) => {
          if (!tagToDelete) return;
          const deleteOk = await deleteMuscleTag(tagToDelete.id);
          if (deleteOk) {
            dispatch({ type: 'delete', payload: tagToDelete });
            refreshMuscleTagMap();
          } else {
            setCannotDelete(true);
          }
          setTagToDelete(undefined);
        }}
      />
      <Typography variant="h4" style={{ color: 'white', margin: 10 }}>
        Muscle Tags:
      </Typography>

      {state.muscleTagList ? (
        <MuscleTagEditor
          tagEditMode={tagEditMode}
          tagClicked={(tag) => {
            setSelectedTag(tag);
          }}
          tagCreated={async (tagName: string) => {
            const newTag = await createMuscleTag({ name: tagName });
            dispatch({ type: 'add', payload: newTag });
            refreshMuscleTagMap();
          }}
          tagRemoved={setTagToDelete}
          tagUpdated={async (tag: MuscleTag) => {
            await updateMuscleTag(tag);
            dispatch({ type: 'edit', payload: tag });
            refreshMuscleTagMap();
          }}
          muscleTags={state.muscleTagList}
        />
      ) : undefined}

      <Typography variant="h4" style={{ color: 'white', marginLeft: 10, marginTop: 20 }}>
        Body Zones:
      </Typography>
      <Box sx={{ m: 1, mt: 2, display: 'flex', alignContent: 'center' }}>
        <TextField
          label="Zone Name"
          value={newBodyZoneName}
          onChange={(e) => {
            setNewBodyZoneName(e.target.value);
          }}
        ></TextField>
        <Button
          onClick={async () => {
            if (newBodyZoneName) {
              const createdBodyZone = await createBodyZone({ isDefaultZone: false, name: newBodyZoneName, zoneTagIds: [] });
              refreshBodyZoneList();
            }
            setNewBodyZoneName('');
          }}
          style={{ color: 'white' }}
        >
          Create new Zone
        </Button>
      </Box>
      <Stack sx={{ m: 1 }}>
        {bodyZoneList?.map((bz, i) => (
          <Box sx={{ borderColor: 'rgb(96, 108, 56)', borderWidth: 1, borderStyle: 'dashed', borderRadius: 3, padding: 1, my: 1 }}>
            <BodyZoneElement
              editModeChanged={(editMode) => {
                setTagEditMode(editMode);
                setSelectedTag(undefined);
              }}
              bodyZoneSaved={(ubz) => {
                refreshBodyZoneList();
              }}
              bodyZoneDeleted={(dbz) => {
                refreshBodyZoneList();
              }}
              bodyZone={bz}
              muscleTagMap={muscleTagMap}
              selectedMuscleTag={selectedTag}
            />
          </Box>
        ))}
      </Stack>
    </Container>
  );
};

interface BodyZoneProps {
  bodyZone: BodyZone;
  muscleTagMap?: Map<string, MuscleTag>;
  selectedMuscleTag?: MuscleTag;
  bodyZoneSaved: (bodyZone: BodyZone) => void;
  bodyZoneDeleted: (bodyZone: BodyZone) => void;
  editModeChanged: (editMode: boolean) => void;
}

const BodyZoneElement = (props: BodyZoneProps) => {
  const [editMode, setEditMode] = useState(false);
  const [bodyZoneName, setBodyZoneName] = useState(props.bodyZone.name);
  const [muscleTagIds, setMuscleTagIds] = useState(props.bodyZone.zoneTagIds);
  const [isDefaultZone, setIsDefaultZone] = useState(props.bodyZone.isDefaultZone);

  useEffect(() => {
    if (editMode && props.selectedMuscleTag && !muscleTagIds.some((bzTagId) => bzTagId === props.selectedMuscleTag?.id))
      setMuscleTagIds([...muscleTagIds, props.selectedMuscleTag?.id]);
    else if (editMode && props.selectedMuscleTag && muscleTagIds.some((bzTagId) => bzTagId === props.selectedMuscleTag?.id))
      setMuscleTagIds(muscleTagIds.filter((tagId) => props.selectedMuscleTag?.id !== tagId));
  }, [props.selectedMuscleTag]);

  return (
    <Box>
      {editMode ? (
        <>
          <Box style={{}}>
            <TextField
              style={{ margin: 10 }}
              type="text"
              value={bodyZoneName}
              onChange={(v) => {
                setBodyZoneName(v.target.value);
              }}
            />
          </Box>
          <Box>
            <MuscleTagIdList muscleTagIds={muscleTagIds} muscleTagMap={props.muscleTagMap} />
          </Box>
          <Box>
            <FormGroup style={{ background: 'black' }}>
              <FormControlLabel control={<Checkbox checked={isDefaultZone} onChange={(e) => setIsDefaultZone(!isDefaultZone)} />} label="Default" />
            </FormGroup>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ m: 1 }}>
            <Typography variant="h6" style={{ color: 'white', marginTop: 10 }}>
              {props.bodyZone.name}
            </Typography>
          </Box>
          <Box sx={{ m: 1 }} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <MuscleTagIdList sx={{ backgroundColor: 'rgb(96, 108, 56)' }} muscleTagIds={props.bodyZone.zoneTagIds} muscleTagMap={props.muscleTagMap} />
          </Box>
        </>
      )}
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'flex-end' }}>
        {editMode ? (
          <Button
            style={{ color: 'white' }}
            onClick={async () => {
              setEditMode(!editMode);
              props.editModeChanged(editMode);
              const updatedBodyZone = await updateBodyZone({ isDefaultZone, name: bodyZoneName, id: props.bodyZone.id, zoneTagIds: muscleTagIds });
              props.bodyZoneSaved(updatedBodyZone);
            }}
          >
            Save
          </Button>
        ) : (
          <Button
            style={{ color: 'white' }}
            onClick={() => {
              setEditMode(!editMode);
              props.editModeChanged(editMode);
            }}
          >
            Edit
          </Button>
        )}
        <Button
          onClick={async () => {
            await deleteBodyZone(props.bodyZone.id);
            props.bodyZoneDeleted(props.bodyZone);
          }}
          style={{ color: 'white' }}
          className="m-3"
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
};
