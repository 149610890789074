import { useEffect, useState } from 'react';
import { copySet } from '../../api/set';
import { Set } from '../../models/set';
import { useSetList } from '../../hooks/set-list.hook';
import { Box, Button, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  pt: 2,
  px: 4,
  pb: 3,
};

export interface CreateSetFromOtherModalProps {
  show: boolean;
  setShow: (s: boolean) => void;
  setRefreshSets: (s: boolean) => void;
}

export const CreateSetFromOtherModal = (props: CreateSetFromOtherModalProps) => {
  const [set, setSet] = useState<Set>();
  const [setDescription, setSetDescription] = useState<string>();
  const setList = useSetList();
  const [setName, setSetName] = useState<string>();

  useEffect(() => {
    if (setList && setList.length > 0) setSet(setList[0]);
  }, [setList]);

  return (
    <Modal open={props.show} onClose={() => props.setShow(false)}>
      <Box sx={style}>
        <Typography variant="h4">Create New Set from existing Set</Typography>
        <Box sx={{ marginTop: 2 }}>
          <Select value={set?.name}>
            {setList?.map((s) => (
              <MenuItem value={s.name} onClick={() => setSet(s)}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <TextField style={{ marginTop: 10 }} onChange={(e) => setSetName(e.target.value)} label="Name" />
        <Box>
          <TextField label={'Set Description'} onChange={(v) => setSetDescription(v.target.value)} style={{ width: 250, marginTop: 10 }} multiline rows={4} />
        </Box>
        <Button
          onClick={async () => {
            props.setShow(false);

            if (set) {
              try {
                await copySet({ sourceSetId: set.id || '', name: setName || set.name + '_copy', description: setDescription });
                props.setRefreshSets(true);
              } catch (e) {
                if (e instanceof Error) {
                  alert(JSON.parse(e.message).message);
                }
              }
            }
          }}
        >
          Create Set
        </Button>
        <Button onClick={() => props.setShow(false)}>Close</Button>
      </Box>
    </Modal>
  );
};
