import { OCRResult } from '../models/ocr-result';
import { fetchWithReauth } from './auth';

export const serverOcr = async (file: Blob): Promise<OCRResult> => {
  console.log(file);
  const formData = new FormData();

  formData.append('file', file);

  const response = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/ocr/extractText`, {
    method: 'POST',
    body: formData,
  });

  return await response.json();
};
