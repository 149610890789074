import { createContext, useContext, useEffect, useState } from 'react';
import { getLocalSetList } from '../api/set';
import { Set } from '../models/set';

export const useSet = (setId?: string): [set: Set | undefined, refreshSet: (force?: boolean) => void] => {
  const [set, setSet] = useState<Set>();

  async function initialize(force?: boolean) {
    const localSetList = await getLocalSetList(force);
    const set = localSetList?.find((s) => s.id === setId);
    setSet(set);
  }
  useEffect(() => {
    initialize();
  }, [setId]);

  return [set, initialize];
};
