import { useEffect, useState } from 'react';
import { Set } from '../models/set';
import { getSetList } from '../api/set';
import { downloadExport } from '../api/export';
import { useNavigate } from 'react-router-dom';
import { useSetList } from '../hooks/set-list.hook';

export function Export() {
  const setList = useSetList();
  const [selectedSet, setSelectedSet] = useState<Set>();
  const navigate = useNavigate();

  useEffect(() => {
    if (setList && setList.length > 0) setSelectedSet(setList[0]);
  }, [setList]);

  return (
    <>
      Set: <SetDropDown setList={setList} selectedSet={selectedSet} setChanged={(s: Set) => setSelectedSet(s)} />
      {/* Theme: <ThemeDropDown themeList={themeList} selectedTheme={selectedTheme} themeChanged={(t: Theme) => setSelectedTheme(t)} /> */}
      <button
        onClick={async () => {
          //TODO: Remove hardcoded theme id.
          const zip = await downloadExport(selectedSet?.id || '', '60ef1cf0ff0e180011206efc' || '');
          const url = window.URL.createObjectURL(zip);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${selectedSet?.name}.zip`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }}
      >
        Export
      </button>
    </>
  );
}

// function ThemeDropDown(props: any){
//     return <select value={props.selectedTheme?.id}  onChange={(e) => {
//       if(props.onChange)
//         props.onChange(e);
//       const selectedTheme = props.themeList?.find((t: Theme) => e.target.value === t.id);
//       if(props.themeChanged)
//         props.themeChanged(selectedTheme);
//     }}>
//       {props.themeList?.map((t : Theme) => <option selected={props.selectedTheme?.id === t.id} value={t.id}>{t.name}</option>)}
//       </select>
//    }

function SetDropDown(props: any) {
  return (
    <select
      value={props.selectedSet?.id}
      style={{ margin: 10 }}
      onChange={(e) => {
        if (props.onChange) props.onChange(e);
        const selectedSet = props.setList?.find((s: Set) => e.target.value === s.id);
        if (props.setChanged) props.setChanged(selectedSet);
      }}
    >
      {props.setList?.map((s: Set) => (
        <option selected={props.selectedSet?.id === s.id} value={s.id!.toString()}>
          {s.name?.toString()}
        </option>
      ))}
    </select>
  );
}
