import { useRef, useState } from 'react';
import { updateSet } from '../../api/set';
import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import { modalStyle } from './modalStyle';
import { Set } from '../../models/set';
import { ImageFromFile } from '../ImageFromFile';
import { Photo } from '@mui/icons-material';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import { imgPreview } from '../../utils/imageRendering';
import { ImageFromServer } from '../ImageFromServer';
import { uploadFile } from '../../api/file';

export interface EditSetModalProps {
  showEditSetModal: boolean;
  set: Set;
  setShowCreateSetModal: (s: boolean) => void;
  refreshSet: () => void;
}

interface ImageCropComponentProps {
  imageId?: string;
  onImageFileChanged?: (file: File | null | undefined) => void;
  imageFile: File | null | undefined;
}

const ImageCropComponent = ({ imageId, onImageFileChanged, imageFile }: ImageCropComponentProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  return (
    <>
      {imageFile ? (
        <>
          <ReactCrop crop={crop} onComplete={(c) => setCompletedCrop(c)} onChange={(c) => setCrop(c)}>
            <ImageFromFile ref={imgRef} thumbnail={true} style={{ maxWidth: 300, maxHeight: 300 }} image={imageFile}></ImageFromFile>
          </ReactCrop>
          <Button
            onClick={async () => {
              if (completedCrop && imgRef.current) {
                const blob = await imgPreview(imgRef.current, completedCrop);
                const imageFile = new File([blob], 'image.jpeg', {
                  type: blob.type,
                });
                if (onImageFileChanged) onImageFileChanged(imageFile);
              }
            }}
          >
            Crop
          </Button>
        </>
      ) : (
        <Box sx={{ width: '50%' }}>
          <ImageFromServer sx={{ maxWidth: 300, maxHeight: 300 }} thumbnail={true} alt="" id={imageId} />
        </Box>
      )}
      <input
        style={{ display: 'none' }}
        onChange={(e) => {
          if (onImageFileChanged) onImageFileChanged((e.target as any).files ? (e.target as any).files[0] : null);
        }}
        accept="image/*"
        id="icon-button-file"
        type="file"
      />
      <label htmlFor="icon-button-file">
        <IconButton color="primary" aria-label="upload picture" component="span">
          <Photo />
          &nbsp; Change Image
        </IconButton>
      </label>
    </>
  );
};

export const EditSetModal = (props: EditSetModalProps) => {
  const { set, showEditSetModal, refreshSet, setShowCreateSetModal } = props;

  const [setDescription, setSetDescription] = useState<string | undefined>(set.description);
  const [setName, setSetName] = useState<string>(set.name);
  const [imageFile, setImageFile] = useState<File | null | undefined>();

  return (
    <Modal open={showEditSetModal} onClose={() => setShowCreateSetModal(false)}>
      <Box sx={modalStyle}>
        <Typography variant="h4">Edit Set</Typography>
        <TextField value={setName} style={{ marginTop: 30 }} onChange={(e) => setSetName(e.target.value)} label="Name" />
        <Box>
          <TextField
            value={setDescription}
            label={'Set Description'}
            onChange={(v) => setSetDescription(v.target.value)}
            style={{ width: 250, marginTop: 10 }}
            multiline
            rows={4}
          />
        </Box>
        <Box sx={{ m: 1 }}>
          <ImageCropComponent imageFile={imageFile} onImageFileChanged={setImageFile} imageId={props.set.imageId} />
        </Box>

        <Button
          style={{ marginTop: 10 }}
          onClick={async () => {
            setShowCreateSetModal(false);
            try {
              let fileId = undefined;
              if (imageFile) {
                fileId = await uploadFile(imageFile);
              }
              await updateSet({ ...set, name: setName || '', description: setDescription, imageId: fileId });
              refreshSet();
            } catch (e) {
              if (e instanceof Error) {
                alert(JSON.parse(e.message).message);
              }
            }
          }}
        >
          Save{' '}
        </Button>
        <Button style={{ marginTop: 10 }} onClick={() => setShowCreateSetModal(false)}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};
