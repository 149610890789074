import { Card, CardActionsProps, CardContent, FormControlLabel, Grow, Switch, Typography } from '@mui/material';
import { User } from '../models/user';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export const UserCard = (props: { user: User; children?: ReactElement<CardActionsProps>; timeout: number }) => {
  const { user, children } = props;

  const navigate = useNavigate();

  return (
    <Grow in={true} timeout={props.timeout}>
      <Card style={{ minHeight: 150, maxWidth: 450 }} className="h-100">
        <CardContent onClick={() => navigate(`/my-sets/${props.user.userId}`)}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {props.user?.username}
          </Typography>
          <Typography variant="body1">Roles: {props.user?.roles.map((r) => r + ' ') || 'No Description'}</Typography>
          <Typography variant="caption">Last Login: {user.lastLogin ? dayjs(user.lastLogin).format('DD.MM.YY HH:mm:ss') : '-'}</Typography>
        </CardContent>
        {children}
      </Card>
    </Grow>
  );
};
