import { Box, Button, Modal, Typography } from '@mui/material';
import { modalStyle } from './modalStyle';

type DeleteModalProps = {
  nameOfObjectToDelete: string;
  show: boolean;
  handleDelete: () => void;
  setShow: (show: boolean) => void;
};

export const DeleteModal = (props: DeleteModalProps) => {
  return (
    <>
      <Modal open={props.show} onClose={() => props.setShow(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h4">Really delete this {props.nameOfObjectToDelete}?</Typography>
          <Box sx={{ marginTop: 5 }}>
            <Button onClick={props.handleDelete}>Delete</Button>
            <Button onClick={() => props.setShow(false)}>Cancel</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
