import { CopyExerciseDTO, Exercise } from '../models/exercise';
import { fetchWithReauth } from './auth';

export async function createExercise(exercise: Exercise): Promise<Exercise> {
  let newExercise = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/exercise`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(exercise),
  });
  if (newExercise.ok) return newExercise.json();
  else throw Error(await newExercise.text());
}

export async function updateExercise(exercise: Exercise) {
  await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/exercise`, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(exercise),
  });
}

export async function copyExercise(copyDTO: CopyExerciseDTO) {
  const copiedExercise = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/exercise/copy`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(copyDTO),
  });
  if (copiedExercise.ok) return copiedExercise.json();
  else throw Error(await copiedExercise.text());
}

export const getExerciseList = async (setId?: string): Promise<Array<Exercise>> =>
  await (await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/exercise/${setId}`)).json();

export async function deleteExercise(id: string): Promise<any> {
  return await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/exercise/${id}`, { method: 'DELETE' });
}
