import { useEffect, useState } from 'react';
import { deleteSet, getLocalSetList, getSetById, getSetsByUser, setDefaultSetId, unassignSet, getDefaultSetId as getDefaultSetIdApi, getAllSets } from '../api/set';
import { Set } from '../models/set';
import { useParams } from 'react-router-dom';
import { getLocalUser, getUserById, isAdminUser } from '../api/user';
import { DeleteModal } from '../components/modals/DeleteSetModal';
import { CreateSetModal } from '../components/modals/CreateSetModal';
import { CreateSetFromOtherModal } from '../components/modals/CreateSetFromOtherModal';
import { Box, Button, CardActions, Checkbox, Container, FormControlLabel, FormGroup, Grid, Grow, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { SetCard } from '../components/SetCard';
import { AddSetDrawer } from '../components/AddSetDrawer';
import { Role } from '../models/user';

const SetRow = (props: SetRowProps) => {
  let user = getLocalUser();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { userId } = useParams();
  const isSetCreator = () => props.set.creatorId === user?.id;

  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        nameOfObjectToDelete="Set"
        handleDelete={async () => {
          await deleteSet({ setId: props.set.id || '', deleteExercises: true });
          props.setRefreshSets(true);
          setShowDeleteModal(false);
        }}
      ></DeleteModal>
      <Grid item xs={12} md={4}>
        <SetCard isDefault={props.isDefault} timeout={1000} set={props.set} isSetCreator={isSetCreator()}>
          <CardActions>
            {isSetCreator() || isAdminUser() ? (
              <Button
                onClick={() => {
                  const ownSetCount = props.setList?.filter((s) => s.creatorId === getLocalUser()?.id).length || 0;

                  if (ownSetCount > 1) {
                    setShowDeleteModal(true);
                  } else {
                    alert('You cannot delete your last set.');
                  }
                }}
                size="small"
              >
                Delete
              </Button>
            ) : (
              <></>
            )}
            {isAdminUser() && !userId && !props.isDefault && (
              <Button
                onClick={async () => {
                  await setDefaultSetId(props.set.id || '');
                  props.setRefreshSets(true);
                }}
              >
                Set as default
              </Button>
            )}
            {isAdminUser() && userId && (
              <Button
                onClick={async () => {
                  if (props.set.id && userId) await unassignSet({ setId: props.set.id, userId: userId });
                  props.setRefreshSets(true);
                }}
              >
                Remove
              </Button>
            )}
          </CardActions>
        </SetCard>
      </Grid>
    </>
  );
};

const getShowAllSetsFromLocalStorage = () => localStorage.getItem('showAllSets') === 'true';
const setShowAllSetsInLocalStorage = (showAllSets: boolean) => localStorage.setItem('showAllSets', String(showAllSets));

const getFilterUsername = () => localStorage.getItem('filterUsername') ?? 'any';
const setFilterUsernameL = (username: string) => localStorage.setItem('filterUsername', username);

export const Sets = () => {
  const [setList, setSetList] = useState<Array<Set>>();
  const [filteredSets, setFilteredSets] = useState<Array<Set>>([]);
  const [filterUsername, setFilterUsername] = useState<string>(getFilterUsername());
  const [refreshSets, setRefreshSets] = useState<boolean>(false);
  const [showCreateSetModal, setShowCreateSetModal] = useState(false);
  const [showCreateSetFromOtherModal, setShowCreateSetFromOtherModal] = useState(false);
  const [showAssignSetDrawer, setShowAssignSetDrawer] = useState(false);
  const [defaultSetId, setDefaultSetId] = useState<string>();
  const [defaultSet, setDefaultSet] = useState<Set>();
  const [showDefaultSet, setShowDefaultSet] = useState<boolean>(false);
  const [showAllSets, setShowAllSets] = useState<boolean>(getShowAllSetsFromLocalStorage());
  let user = getLocalUser();
  const { userId } = useParams();
  const userSelectionMode = userId !== undefined;
  const setUserNames: Array<string> = [];
  setList?.forEach((s) => {
    if (!setUserNames.includes(s.creatorName)) setUserNames.push(s.creatorName);
  });

  useEffect(() => {
    const getDefaultSetId = async () => {
      const defaultSetId = await getDefaultSetIdApi();
      setDefaultSetId(defaultSetId);
      const defaultSet = await getSetById(defaultSetId);
      setDefaultSet(defaultSet);
    };
    getDefaultSetId();
  }, [refreshSets]);

  useEffect(() => {
    async function initialize() {
      if (!setList || refreshSets) {
        let setList;

        if (showAllSets) {
          setList = await getAllSets();
        } else if (userId) {
          const user = await getUserById(userId);
          setList = await getSetsByUser(user);
        } else {
          setList = await getLocalSetList(true);
        }
        setSetList(setList);
        setRefreshSets(false);
      }
    }
    initialize();
  }, [setList, refreshSets, showAllSets]);

  useEffect(() => {
    if (setList) {
      let filtered = setList;
      if (!userSelectionMode && !showAllSets && filterUsername !== 'any') filtered = filtered.filter((s) => s.creatorName === filterUsername);
      setFilteredSets(filtered);
    }
  }, [setList, showAllSets, filterUsername]);

  return (
    <>
      <Container>
        {userId && <AddSetDrawer setAssigned={() => setRefreshSets(true)} setShow={setShowAssignSetDrawer} show={showAssignSetDrawer} userId={userId} />}
        {}
        <Paper sx={{ my: 1, zIndex: 1, padding: 1, position: 'sticky', top: { xs: 60, sm: 65 } }}>
          <Grid sx={{ alignContent: 'center', alignItems: 'center' }} container gap={2} direction={'row'}>
            {!userSelectionMode && (
              <Grid item>
                <Button variant="outlined" onClick={() => setShowCreateSetModal(true)}>
                  Create Set
                </Button>
              </Grid>
            )}
            {!userSelectionMode && (
              <Grid item>
                <Button variant="outlined" onClick={() => setShowCreateSetFromOtherModal(true)}>
                  Create From other Set
                </Button>
              </Grid>
            )}
            {userSelectionMode && (
              <Grid item>
                <Button onClick={() => setShowAssignSetDrawer(true)}>Assign Set</Button>
              </Grid>
            )}
            {user?.roles.includes(Role.Admin) && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(v) => {
                        setShowAllSets(!showAllSets);
                        setShowAllSetsInLocalStorage(!showAllSets);
                        setRefreshSets(true);
                      }}
                      checked={showAllSets}
                    />
                  }
                  label="All Sets"
                />
              </Grid>
            )}
            {!userSelectionMode && user?.roles.includes(Role.Admin) && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(v) => {
                        setShowDefaultSet(!showDefaultSet);
                      }}
                      checked={showDefaultSet}
                    />
                  }
                  label="Default Set"
                />
              </Grid>
            )}
            {!userSelectionMode && (
              <Grid item>
                <Stack gap={1} sx={{ alignItems: 'center' }} direction={'row'}>
                  <Typography>Owner</Typography>
                  <Select
                    value={filterUsername.length ? filterUsername : 'any'}
                    onChange={(e) => {
                      setFilterUsername(e.target.value);
                      setFilterUsernameL(e.target.value);
                    }}
                  >
                    <MenuItem selected key={'any'} value={'any'}>
                      Any
                    </MenuItem>
                    {setUserNames?.map((username) => (
                      <MenuItem key={username} value={username}>
                        {username === getLocalUser()?.username ? 'You' : username}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Paper>

        {showDefaultSet && (
          <Box sx={{ m: 2 }}>
            <Typography sx={{ my: 1 }} variant="h6">
              Default Set{' '}
            </Typography>
            {defaultSet && <SetCard set={defaultSet} isSetCreator={false} timeout={1000} />}
          </Box>
        )}
        <Grid container direction={'row'} spacing={2}>
          {filteredSets.map((s: Set, i) => (
            <SetRow key={s.id} isDefault={defaultSetId === s.id} setRefreshSets={setRefreshSets} set={s} setList={filteredSets} />
          ))}
        </Grid>
        <CreateSetFromOtherModal setRefreshSets={setRefreshSets} show={showCreateSetFromOtherModal} setShow={setShowCreateSetFromOtherModal}></CreateSetFromOtherModal>
        <CreateSetModal setRefreshSets={setRefreshSets} showCreateSetModal={showCreateSetModal} setShowCreateSetModal={setShowCreateSetModal}></CreateSetModal>
      </Container>
    </>
  );
};

interface SetRowProps {
  setList: Array<Set>;
  isDefault: boolean;
  set: Set;
  setRefreshSets: (refresh: boolean) => void;
}
