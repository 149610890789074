import { useEffect, useState } from 'react';
import { getLocalSetList } from '../api/set';
import { Set } from '../models/set';

export function useSetList() {
  const [setList, setSetList] = useState<Array<Set>>();

  useEffect(() => {
    async function initialize() {
      if (!setList) {
        const sl = await getLocalSetList();
        setSetList(sl);
      }
    }
    initialize();
  }, [setList]);

  return setList;
}
