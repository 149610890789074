import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { isAdminUser, isLoggedIn } from '../api/user';
import { logout } from '../api/auth';
import { useNavigate } from 'react-router-dom';
import { FileDownload, Filter1, Group, Logout, PermMedia, Style, HelpOutline } from '@mui/icons-material';

export const NavDrawer = (props: { open: boolean; setDrawerOpen: (open: boolean) => void }) => {
  const navigate = useNavigate();
  return (
    <Drawer open={props.open} onClose={() => props.setDrawerOpen(false)}>
      <Box sx={{ width: 250 }} role="presentation">
        <Typography sx={{ mt: 2, ml: 1.5 }} variant="h5">
          Your Fitness Plans
        </Typography>
        <List>
          <ListItem key={'UserGuide'} disablePadding>
            <ListItemButton href="/user-guide">
              <ListItemIcon>
                <HelpOutline />{' '}
              </ListItemIcon>
              <ListItemText primary={'User Guide'} />
            </ListItemButton>
          </ListItem>
          {isLoggedIn() ? (
            <ListItem key={'Sets'} disablePadding>
              <ListItemButton href="/my-sets">
                <ListItemIcon>
                  <Style />{' '}
                </ListItemIcon>
                <ListItemText primary={'Sets'} />
              </ListItemButton>
            </ListItem>
          ) : (
            <></>
          )}

          {isAdminUser() ? (
            <>
              <ListItem key={'Themes'} disablePadding>
                <ListItemButton href="/themes">
                  <ListItemIcon>
                    <PermMedia />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Themes'} />
                </ListItemButton>
              </ListItem>
              <ListItem key={'Export'} disablePadding>
                <ListItemButton href="/export">
                  <ListItemIcon>
                    <FileDownload />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Export'} />
                </ListItemButton>
              </ListItem>
              <ListItem key={'BodyZones'} disablePadding>
                <ListItemButton href="/body-zones">
                  <ListItemIcon>
                    <Filter1 />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Body Zones'} />
                </ListItemButton>
              </ListItem>
              <ListItem key={'Users'} disablePadding>
                <ListItemButton href="/users">
                  <ListItemIcon>
                    <Group />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Users'} />
                </ListItemButton>
              </ListItem>
            </>
          ) : (
            <></>
          )}

          <ListItem key={'Logout'} disablePadding>
            <ListItemButton
              onClick={() => {
                logout();
                navigate('/login');
              }}
            >
              <ListItemIcon>
                <Logout />{' '}
              </ListItemIcon>
              <ListItemText primary={isLoggedIn() ? 'Logout' : 'Login Screen'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};
