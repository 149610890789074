import { ExerciseLink } from './exercise-link';
import { MuscleIntensity } from './muscle-intensity';

export const createEmptyExercise = (): Exercise => {
  return { name: '', active: true, description: '', multiLimb: false, muscleIntensities: [], templateConfig: {}, mainBodyZoneId: '', exerciseLinks: [] };
};

export interface Exercise {
  id?: string;
  name: string;
  setId?: string;
  description: string;
  templateConfig: TemplateConfig;
  imageId?: string;
  videoId?: string;
  multiLimb: boolean;
  muscleIntensities: Array<MuscleIntensity>;
  exerciseLinks: Array<ExerciseLink>;
  mainBodyZoneId: string;
  active: boolean;
}

export interface TemplateConfig {
  weight?: number;
  repetitions?: number;
  time?: number;
  initTime?: number;
  breakTime?: number;
  useTimer?: boolean;
}

export interface CopyExerciseDTO {
  exerciseId: string;
  targetSetId: string;
}
