import { useContext, useEffect, useState } from 'react';
import { getExerciseList, copyExercise } from '../api/exercise';
import { getLocalSetList } from '../api/set';
import { Exercise } from '../models/exercise';
import { ExerciseSmall } from './ExerciseSmall';
import { Set } from '../models/set';
import { FilterProps } from '../models/filter-props';
import { filter } from '../utils/exercise-filter';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Drawer, FormControl, InputAdornment, InputLabel, MenuItem, NativeSelect, Paper, Select, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { AppContext } from '../App';

type AddExercisesDrawerProps = {
  set?: Set;
  show: boolean;
  setShow: (show: boolean) => void;
  exerciseList?: Array<Exercise>;
  exerciseAdded: (exercise: Exercise) => void;
};

export const AddExercisesDrawer = (props: AddExercisesDrawerProps) => {
  const { exerciseAdded } = props;
  const context = useContext(AppContext);
  const [exerciseList, setExerciseList] = useState<Array<Exercise>>();
  const [set, setSet] = useState<Set>();
  const [setList, setSetList] = useState<Array<Set>>();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [addedExerciseName, setAddedExerciseName] = useState<string>();
  const [filterProps, setFilterProps] = useState<FilterProps>();
  const [filteredExercises, setFilteredExercises] = useState<Array<Exercise>>();

  const exerciseClicked = async (e: Exercise) => {
    setShowToast(true);
    try {
      const newExercise = await copyExercise({ exerciseId: e.id!, targetSetId: props.set?.id || '' });
      setAddedExerciseName(newExercise.name);
      exerciseAdded(newExercise);
    } catch (e) {
      if (e instanceof Error) {
        alert(JSON.parse(e.message).message);
      }
    }
  };

  useEffect(() => {
    async function initialize() {
      if (!setList) {
        const localSetList = await getLocalSetList();
        setSetList(localSetList);
        setSet(localSetList[0]);
      }
    }
    initialize();
  }, [setList]);

  useEffect(() => {
    if (filterProps && exerciseList) setFilteredExercises(filter(exerciseList, filterProps));
    else setFilteredExercises(exerciseList);
  }, [filterProps, exerciseList]);

  useEffect(() => {
    async function initialize() {
      if (set) {
        const l = await getExerciseList(set.id);
        setExerciseList(l);
      }
    }
    initialize();
  }, [set]);

  return (
    <>
      <Drawer anchor="right" open={props.show} onClose={() => props.setShow(false)}>
        <Box>
          <Paper elevation={30} sx={{ position: 'sticky', top: 0, pb: 3 }}>
            <Stack direction={'row'} style={{ justifyItems: 'center', justifyContent: 'center', alignItems: 'center' }}>
              <FormControl sx={{ mx: 2, mb: 2 }} fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Zone
                </InputLabel>
                <NativeSelect
                  id="input-group-dropdown-1"
                  value={filterProps?.bodyZoneId !== '' && filterProps?.bodyZoneId ? filterProps?.bodyZoneId : 'any'}
                  onChange={(e) => {
                    if (e.target.value !== 'any') setFilterProps({ ...filterProps, bodyZoneId: e.target.value as any });
                    else setFilterProps({ ...filterProps, bodyZoneId: '' });
                  }}
                >
                  <option key={'any'} value={'any'}>
                    Any
                  </option>
                  {context.bodyZoneList?.map((bz) => (
                    <option key={bz.id} value={bz.id}>
                      {bz.name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Stack>
            <Stack direction={'row'} style={{ marginTop: 4, justifyContent: 'center', alignContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
              <TextField
                label="Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setFilterProps({ ...filterProps, name: e.target.value });
                }}
              />
            </Stack>
            <Stack direction={'row'} style={{ marginTop: 10, justifyItems: 'center', justifyContent: 'center', alignItems: 'center' }}>
              <FormControl>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  from Set
                </InputLabel>
                <NativeSelect
                  value={set?.name}
                  style={{ zIndex: 1 }}
                  id="input-group-dropdown-1"
                  onChange={(e) => {
                    setSet(setList?.find((s) => s.name === e.target.value));
                  }}
                >
                  {setList?.map((s) => (
                    <option value={s.name} onClick={() => setSet(s)}>
                      {s.name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Stack>
          </Paper>
          <Box sx={{ marginTop: 2 }} width={250}>
            {filteredExercises?.map((e) => (
              <ExerciseSmall onClick={() => exerciseClicked(e)} key={e.id} exercise={e} />
            ))}
          </Box>
          <Snackbar
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            open={showToast}
            autoHideDuration={2000}
            onClose={() => setShowToast(false)}
            message={`${addedExerciseName} added to Set!`}
          />
        </Box>
      </Drawer>
    </>
  );
};
