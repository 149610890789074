import { Theme } from '../models/theme';
import { fetchWithReauth } from './auth';

export async function getThemeNameList(): Promise<Array<string>> {
  return await (await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/theme`)).json();
}

export async function getThemeList(): Promise<Array<Theme>> {
  return await await (await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/theme/list`)).json();
}

export async function getThemeByName(name: string): Promise<Theme> {
  return await (await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/theme/${name}`)).json();
}

export async function updateTheme(theme: Theme): Promise<Theme> {
  return await (
    await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/theme/update`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(theme),
    })
  ).json();
}
