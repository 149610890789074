import { validateSet } from '../api/set';
import { useEffect, useState } from 'react';
import { SetValidationResult } from '../models/set-validation-result';

export const useValidateSet = (setId?: string): [set: SetValidationResult | undefined, validate: () => void] => {
  const [setValidationResult, setSetValidationResult] = useState<SetValidationResult>();

  async function validate(setId?: string) {
    if (setId) {
      const validationResult = await validateSet(setId);
      setSetValidationResult(validationResult);
    }
  }

  useEffect(() => {
    validate(setId);
  }, [setId]);

  return [setValidationResult, () => validate(setId)];
};
