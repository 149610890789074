import { BodyZone, CreateBodyZone } from '../models/body-zone';
import { fetchWithReauth } from './auth';

export const getBodyZoneList = async (): Promise<Array<BodyZone>> => {
  const getBodyZones = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/body-zone`, {});
  return getBodyZones.ok ? await getBodyZones.json() : undefined;
};

export const createBodyZone = async (newBodyZone: CreateBodyZone): Promise<BodyZone> => {
  const bodyZone = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/body-zone`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(newBodyZone),
  });
  return bodyZone.ok ? await bodyZone.json() : undefined;
};

export const updateBodyZone = async (updatedBodyZone: BodyZone): Promise<BodyZone> => {
  const muscleTag = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/body-zone`, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(updatedBodyZone),
  });
  return muscleTag.ok ? await muscleTag.json() : undefined;
};

export const deleteBodyZone = async (id: string): Promise<void> => {
  const deleteTag = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/body-zone/${id}`, {
    method: 'DELETE',
  });
};
