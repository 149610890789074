import 'react-image-crop/dist/ReactCrop.css';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getLocalUser } from '../api/user';
import { DisplayMode, ExerciseComponent } from '../components/ExerciseComponent';
import { Exercise } from '../models/exercise';
import { CreateExerciseModal } from '../components/modals/CreateExerciseModal';
import { AddExercisesDrawer } from '../components/AddExerciseDrawer';
import { TextScanModal } from '../components/modals/TextScanModal';
import { EditExerciseModal } from '../components/modals/EditExerciseModal';
import { FilterProps } from '../models/filter-props';
import { filter } from '../utils/exercise-filter';
import { AppContext } from '../App';
import SearchIcon from '@mui/icons-material/Search';
import { Tooltip, Box, Button, Container, Fab, Grid, InputAdornment, MenuItem, Paper, Select, Stack, SxProps, TextField, Typography, Zoom, IconButton } from '@mui/material';
import { useSet } from '../hooks/set.hook';
import { useExercises } from '../hooks/exercises.hook';
import { useValidateSet } from '../hooks/validate-set.hook';
import EditIcon from '@mui/icons-material/Edit';
import { EditSetModal } from '../components/modals/EditSetModal';
import { Role } from '../models/user';
import { ImageFromServer } from '../components/ImageFromServer';
import { List, ListAlt, ListOutlined, ViewCompactAlt, ViewCompactOutlined } from '@mui/icons-material';
import { ViewCompact } from '@mui/icons-material';

const fabStyle = {
  position: 'fixed',
  bottom: 16,
  right: 16,
};

const fab = {
  color: 'primary' as 'primary',
  sx: fabStyle as SxProps,
  icon: <EditIcon />,
  label: 'Edit',
};

const getShowExercisesDisplayMode = () => localStorage.getItem('exercisesDisplayMode');
const setShowExercisesDisplayMode = (displayMode: DisplayMode) => localStorage.setItem('exercisesDisplayMode', String(displayMode));

export const Exercises = () => {
  let user = getLocalUser();
  const context = useContext(AppContext);
  const [showExerciseAdder, setShowExerciseAdder] = useState(false);
  const [showSetEditModal, setShowSetEditModal] = useState(false);
  const [showExerciseCreateModal, setShowExerciseCreateModal] = useState(false);
  const [showTextScanModal, setShowTextScanModal] = useState(false);
  const [textScanResult, setTextScanResult] = useState('');
  const [selectedExercise, setSelectedExercise] = useState<Exercise>();
  const [filterProps, setFilterProps] = useState<FilterProps>();
  const [filteredExercises, setFilteredExercises] = useState<Array<Exercise>>();
  const isSetOwner = () => set?.creatorId === user?.id || user?.roles.includes(Role.Admin);
  const { setId } = useParams();
  const [set, refreshSet] = useSet(setId);
  const [validationResult, revaliate] = useValidateSet(setId);
  const [displayMode, setDisplayMode] = useState(Number(getShowExercisesDisplayMode() ?? DisplayMode.Details));

  const [exerciseList, refreshExercises] = useExercises(setId);

  if (set) context.setExtraTitle(' - ' + set?.name);

  useEffect(() => {
    if (filterProps && exerciseList) setFilteredExercises(filter(exerciseList, filterProps));
    else setFilteredExercises(exerciseList);
  }, [filterProps, exerciseList]);

  return (
    <>
      <Fab
        onClick={() => {
          setShowSetEditModal(true);
        }}
        sx={fab.sx}
        aria-label={fab.label}
        color={fab.color}
      >
        {fab.icon}
      </Fab>
      <AddExercisesDrawer
        set={set}
        show={showExerciseAdder}
        setShow={setShowExerciseAdder}
        exerciseList={exerciseList}
        exerciseAdded={() => {
          refreshExercises();
          revaliate();
        }}
      />

      <Container>
        <Paper sx={{ my: 0, zIndex: 1, padding: 1, position: 'sticky', top: { xs: 60, sm: 65 } }}>
          <Grid container gap={2} sx={{ alignContent: 'center', alignItems: 'center' }}>
            <Grid item>
            <ImageFromServer sx={{ maxWidth: 60, maxHeight: 60 }} thumbnail={false} alt="" id={set?.imageId} />
            </Grid>
            <Grid item>
            <TextField
              label="Filter"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setFilterProps({ ...filterProps, name: e.target.value });
              }}
            />
            </Grid>
            <Grid item>
            <Stack gap={2} direction={'row'} sx={{alignItems:'center'}}>
            <Typography sx={{ textAlign: 'center'}}>Zone</Typography>
            <Select
              value={filterProps?.bodyZoneId !== '' && filterProps?.bodyZoneId ? filterProps?.bodyZoneId : 'any'}
              onChange={(e) => {
                if (e.target.value !== 'any') setFilterProps({ ...filterProps, bodyZoneId: e.target.value as any });
                else setFilterProps({ ...filterProps, bodyZoneId: '' });
              }}
            >
              <MenuItem key={'any'} value={'any'}>
                Any
              </MenuItem>
              {context.bodyZoneList?.map((bz) => (
                <MenuItem key={bz.id} value={bz.id}>
                  {bz.name}
                </MenuItem>
              ))}
            </Select>
            </Stack>
            </Grid>
            <Grid item>
            <Button variant='outlined'  onClick={() => setShowExerciseCreateModal(true)}>New</Button>
            </Grid>
            <Grid item>
            <Button
            variant='outlined'
              onClick={() => {
                setShowExerciseAdder(true);
              }}
            >Copy
            </Button>
            </Grid>
            <Grid item>
            <Box sx={{border:1, p:1, borderRadius:1, color:'primary.main'}}>
              {validationResult?.isValid ? (
                <Typography>Set is Valid</Typography>
              ) : (
                <Tooltip title={validationResult?.validationErrors}>
                 <Typography>Set is Invalid</Typography>
                </Tooltip>
              )}
            </Box>
            </Grid>
            <Stack sx={{ flex: 1, justifyContent: 'flex-end' }} direction={'row'}>
              <IconButton
                sx={{}}
                onClick={() => {
                  setShowExercisesDisplayMode(DisplayMode.Details);
                  setDisplayMode(DisplayMode.Details);
                }}
              >
                {displayMode === DisplayMode.Details ? <ListAlt /> : <List />}
              </IconButton>
              <IconButton
                onClick={() => {
                  setShowExercisesDisplayMode(DisplayMode.Card);
                  setDisplayMode(DisplayMode.Card);
                }}
              >
                {displayMode === DisplayMode.Card ? <ViewCompactAlt /> : <ViewCompact />}
              </IconButton>
            </Stack>
          </Grid>
        </Paper>
        {set ? <EditSetModal set={set} refreshSet={() => refreshSet(true)} setShowCreateSetModal={setShowSetEditModal} showEditSetModal={showSetEditModal} /> : undefined}
        <TextScanModal
          show={showTextScanModal}
          onHide={(text: string) => {
            setTextScanResult(text);
            setShowTextScanModal(false);
          }}
          setShow={() => {
            setShowTextScanModal(false);
          }}
        />
        <CreateExerciseModal
          setId={setId}
          showTextScanModal={() => {
            setShowTextScanModal(true);
          }}
          textScanModalResult={textScanResult}
          exerciseCreated={(e) => {
            if (exerciseList) refreshExercises();
            revaliate();
          }}
          show={showExerciseCreateModal}
          setShow={setShowExerciseCreateModal}
        />
        {selectedExercise ? (
          <EditExerciseModal
            setExercise={setSelectedExercise}
            exercise={selectedExercise}
            set={set}
            showTextScanModal={() => {
              setShowTextScanModal(true);
            }}
            textScanModalResult={textScanResult}
            exerciseUpdated={(ex) => {
              if (exerciseList) refreshExercises();
              revaliate();
            }}
          />
        ) : (
          <></>
        )}

        <Grid gap={displayMode === DisplayMode.Details ? 1 : 0} spacing={1} sx={{ my: 1 }} container>
          {
            filteredExercises?.map((e, i) => (
                <ExerciseComponent
                  display={displayMode}
                  timeout={1000}
                  key={e.id}
                  onExerciseDeleted={() => {
                    exerciseList?.filter((ex) => ex.id !== e?.id);
                    revaliate();
                  }}
                  onClick={() => (isSetOwner() ? setSelectedExercise(e) : undefined)}
                  exercise={e}
                />
              ))    
              }
        </Grid>
      </Container>
    </>
  );
};
