export interface MuscleIntensity {
  muscleId: string;
  intensity: Intensity;
}
export enum Intensity {
  veryLow,
  low,
  medium,
  high,
  veryHigh,
}
