import { useNavigate } from 'react-router';
import { ForgotPasswordModal } from '../components/modals/ForgotPasswordModal';

export const ForgotPassword = () => {
  const navigate = useNavigate();

  return (
    <ForgotPasswordModal
      show={true}
      setShow={() => {
        navigate('/login');
      }}
    ></ForgotPasswordModal>
  );
};
