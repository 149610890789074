import { useEffect, useState } from 'react';
import { getExerciseList } from '../api/exercise';
import { Exercise } from '../models/exercise';

export const useExercises = (setId?: string): [Array<Exercise> | undefined, () => void] => {
  const [exerciseList, setExerciseList] = useState<Array<Exercise>>();

  async function refreshExercises() {
    const l = await getExerciseList(setId);
    setExerciseList(l);
  }

  useEffect(() => {
    refreshExercises();
  }, []);

  return [exerciseList, refreshExercises];
};
