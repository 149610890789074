import { MuscleTag, CreateMuscleTag } from '../models/body-zone';
import { fetchWithReauth } from './auth';

export const getMuscleTagList = async (): Promise<Array<MuscleTag>> => {
  const muscleTagList = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/muscle-tag`, {});
  return muscleTagList.ok ? await muscleTagList.json() : [];
};

export const createMuscleTag = async (newMuscleTag: CreateMuscleTag): Promise<MuscleTag> => {
  const muscleTag = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/muscle-tag/create`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(newMuscleTag),
  });
  return muscleTag.ok ? await muscleTag.json() : undefined;
};

export const updateMuscleTag = async (updatedMuscleTag: MuscleTag): Promise<MuscleTag> => {
  const muscleTag = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/muscle-tag/update`, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(updatedMuscleTag),
  });
  return muscleTag.ok ? await muscleTag.json() : undefined;
};

export const deleteMuscleTag = async (id: string): Promise<boolean> => {
  const deleteTag = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/muscle-tag/${id}`, {
    method: 'DELETE',
  });
  return deleteTag.ok;
};
