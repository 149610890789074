import { useState } from 'react';
import { createUser } from '../../api/user';
import { CreateUser, Role, User } from '../../models/user';
import { Box, Button, Checkbox, FormControlLabel, Modal, TextField, Typography } from '@mui/material';
import { modalStyle } from './modalStyle';

export interface CreateUserModalProps {
  showCreateUserModal: boolean;
  setShowCreateUserModal: (s: boolean) => void;
}

export const CreateUserModal = (props: CreateUserModalProps) => {
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isUploader, setIsUploader] = useState<boolean>(false);
  const [isUser, setIsUser] = useState<boolean>(true);
  const [isActive, setIsActive] = useState<boolean>(true);

  return (
    <Modal open={props.showCreateUserModal} onClose={() => props.setShowCreateUserModal(false)}>
      <Box sx={modalStyle}>
        <Typography sx={{ margin: 2 }} variant="h5">
          Create New User
        </Typography>
        <TextField onChange={(e) => setUserName(e.target.value)} label="Name" />
        &nbsp;
        <TextField type="password" onChange={(e) => setPassword(e.target.value)} />
        <div key={`roles`} className="mb-3">
          <FormControlLabel control={<Checkbox checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} />} label="Admin" />
          <FormControlLabel control={<Checkbox checked={isUploader} onChange={(e) => setIsUploader(e.target.checked)} />} label="Uploader" />
          <FormControlLabel control={<Checkbox checked={isUser} onChange={(e) => setIsUser(e.target.checked)} />} label="User" />
        </div>
        <FormControlLabel control={<Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />} label="Active" />
        <Box>
          <Button onClick={() => props.setShowCreateUserModal(false)}>Close</Button>
          <Button
            onClick={async () => {
              const roles: Array<Role> = [];
              if (isAdmin) roles.push(Role.Admin);
              if (isUploader) roles.push(Role.Uploader);
              if (isUser) roles.push(Role.User);

              const newUser: CreateUser = { active: isActive, roles, password, username: userName };
              const result = await createUser(newUser);
              props.setShowCreateUserModal(false);
            }}
          >
            Create User
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
