import { RegisterUser, User } from '../models/user';

export async function registerUser(newUser: RegisterUser): Promise<User> {
  let resp = await fetch(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/user/register`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newUser),
  });
  if (resp.ok) return resp.json();
  else throw new Error(JSON.stringify(await resp.json()));
}
