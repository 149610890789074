import { Stack, Typography, Box, Button, TextField, Link } from '@mui/material';
import { useState } from 'react';
import { Exercise } from '../models/exercise';

export const ExternalLinksComponent = (props: { exercise: Exercise; setExercise: (exercise: Exercise) => void }) => {
  const [linkTitle, setLinkTitle] = useState<string>('');
  const [linkUri, setLinkUri] = useState<string>('');

  return (
    <Stack>
      <Typography>External Links</Typography>
      {props.exercise.exerciseLinks?.map((el) => (
        <Box sx={{ m: 0.5 }}>
          <Link href={el.uri}>{el.title}</Link>
          <Button
            onClick={() => {
              props.setExercise({ ...props.exercise, exerciseLinks: props.exercise.exerciseLinks.filter((exel) => exel.title !== el.title && exel.uri !== el.uri) });
            }}
          >
            Remove
          </Button>
        </Box>
      ))}

      <Stack direction={'row'} alignItems={'center'}>
        <TextField
          label={'Uri'}
          value={linkUri}
          onChange={(e) => {
            setLinkUri(e.target.value);
          }}
        />
        <TextField
          label={'Title'}
          sx={{ m: 1 }}
          value={linkTitle}
          onChange={(e) => {
            setLinkTitle(e.target.value);
          }}
        />
        <Button
          sx={{}}
          onClick={() => {
            if (!linkTitle || !linkUri) return;
            props.setExercise({ ...props.exercise, exerciseLinks: [...(props.exercise.exerciseLinks || []), { title: linkTitle, uri: linkUri }] });
            setLinkTitle('');
            setLinkUri('');
          }}
        >
          Add
        </Button>
      </Stack>
    </Stack>
  );
};
