import { useState } from 'react';
import { login, loginWithGoogleCode } from '../api/auth';
import { ForgotPasswordModal } from '../components/modals/ForgotPasswordModal';
import title from '../images/title.png';
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { Google } from '@mui/icons-material';

export function Login(props: any) {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const googleLoginWithRedirect = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      await loginWithGoogleCode(codeResponse.code);
      window.location.href = props.redirectLocation;
    },
    flow: 'auth-code',
  });

  return (
    <Container maxWidth="sm">
      <Stack style={{ alignContent: 'center', alignItems: 'center' }} gap={2}>
        <Typography sx={{ mt: 3 }} variant="h3" gutterBottom>
          Your Fitness Plans
        </Typography>
        <Typography variant="h5" gutterBottom>
          Manage your Sets and Exercises
        </Typography>
        <Box style={{ width: '70%' }} component={'img'} src={title}></Box>
        <Stack gap={3} direction={'row'}></Stack>
        <Stack gap={2} sx={{ width: '50%' }} alignItems={'center'} component="form" noValidate autoComplete="off">
          <TextField
            id="filled-basic"
            fullWidth
            value={email}
            label="Email Address"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            fullWidth
            type="password"
            label="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <Button variant="outlined" fullWidth style={{ color: 'white' }} onClick={() => loginWithRedirect(email || '', password || '', props.redirectLocation)}>
            Login
          </Button>
          <Button variant="outlined" startIcon={<Google />} onClick={googleLoginWithRedirect} fullWidth style={{ color: 'white' }}>
            Login with Google
          </Button>
          <Box style={{ color: 'white' }}>
            <a
              style={{ textDecoration: 'underline' }}
              onClick={() => {
                setShowForgotPasswordModal(true);
              }}
            >
              Forgout your Password?
            </a>
          </Box>
          <Box sx={{ mb: 2 }}>
            Not registered yet?{' '}
            <a style={{ color: 'white' }} href="/register">
              Register
            </a>
          </Box>
        </Stack>
      </Stack>

      <ForgotPasswordModal show={showForgotPasswordModal} setShow={setShowForgotPasswordModal}></ForgotPasswordModal>
    </Container>
  );
}

const loginWithRedirect = async (username: string, password: string, redirectLocation: string) => {
  const r = await login({ username: username ?? '', password: password ?? '' });
  window.location.href = redirectLocation;
};
