import { Select, MenuItem, Typography, Box, Chip } from '@mui/material';
import { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../App';
import { BodyZone } from '../../models/body-zone';
import { Exercise } from '../../models/exercise';
import { TagIntensityList } from '../TagIntensityList';
import { TagEditModal } from '../modals/TagEditModal';
import AddIcon from '@mui/icons-material/Add';

export const TagConfiguration = (props: { exercise: Exercise; setExercise: (exercise: Exercise) => void }) => {
  const { bodyZoneList, muscleTagMap } = useContext(AppContext);
  const [mainBodyZoneCandidates, setMainBodyZoneCandidates] = useState<BodyZone[]>();
  const [showTagEditModal, setShowTagEditModal] = useState<boolean>(false);

  useEffect(() => {
    setMainBodyZoneCandidates(bodyZoneList?.filter((bz) => bz.zoneTagIds.some((b) => props.exercise?.muscleIntensities.some((mi) => mi.muscleId === b))));
  }, [props.exercise]);

  return (
    <>
      <TagEditModal
        selectedMuscleIntensities={props.exercise.muscleIntensities}
        setSelectedMuscleIntensities={(mi) => props.setExercise({ ...props.exercise, muscleIntensities: mi })}
        setShow={setShowTagEditModal}
        show={showTagEditModal}
      ></TagEditModal>
      <Select sx={{ my: 1 }} value={props.exercise.mainBodyZoneId ?? ''} displayEmpty>
        <MenuItem value="">Not Selected</MenuItem>
        {mainBodyZoneCandidates?.map((z, i) => (
          <MenuItem
            key={i}
            selected={z.id === props.exercise?.mainBodyZoneId}
            value={z.id}
            onClick={(e) => {
              if (props.exercise) props.setExercise({ ...props.exercise, mainBodyZoneId: z.id });
            }}
          >
            {z.name}
          </MenuItem>
        ))}
      </Select>
      <Typography sx={{ marginBottom: 1 }} variant="h6">
        Tags:
      </Typography>
      <Box>
        <TagIntensityList
          elementClicked={(mi, del) => {
            setShowTagEditModal(true);
          }}
          editable={false}
          muscleIntensities={props.exercise.muscleIntensities}
        />
      </Box>
      {props.exercise.muscleIntensities.length === 0 ? (
        <Chip
          sx={{ my: 0.5, display: 'flex', alignItems: 'center', alignContent: 'center', backgroundColor: '#606C38', fontSize: 14 }}
          icon={<AddIcon />}
          onClick={() => setShowTagEditModal(true)}
        />
      ) : (
        <></>
      )}
    </>
  );
};
