import { Exercise } from '../models/exercise';
import { ImageFromServer } from './ImageFromServer';
import { TagIntensityList } from './TagIntensityList';
import { useContext, useState } from 'react';
import { AppContext } from '../App';
import { Box, Button, Card, CardContent, Fade, Grid, Grow, Link, Paper, Stack, Typography } from '@mui/material';
import { DeleteModal } from './modals/DeleteSetModal';
import { deleteExercise } from '../api/exercise';

export enum DisplayMode {
  Details,
  Card,
}

interface ExerciseProps {
  display: DisplayMode;
  exercise: Exercise;
  timeout: number;
  onClick?: () => void;
  onExerciseDeleted?: () => void;
}

export const ExerciseComponent = (props: ExerciseProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        nameOfObjectToDelete="Exercise"
        handleDelete={async () => {
          await deleteExercise(props.exercise?.id ?? '');
          setShowDeleteModal(false);
          if (props.onExerciseDeleted) props.onExerciseDeleted();
        }}
      ></DeleteModal>
      {props.display === DisplayMode.Card && <CardComponent {...props} setShowDeleteModal={setShowDeleteModal} />}
      {props.display === DisplayMode.Details && <DetailsComponent {...props} setShowDeleteModal={setShowDeleteModal} />}
    </>
  );
};

const CardComponent = (props: ExerciseProps & { setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const context = useContext(AppContext);
  const { exercise } = props;
  return (
    <Grid onClick={props.onClick} xs={12} md={4} item>
      <Card sx={{ minHeight: 150, maxWidth: 450 }}>
        <CardContent>
          <Grid container>
            <Grid item xs={5}>
              <ImageFromServer sx={{ flex: 1, maxWidth: 100, maxHeight: 100 }} thumbnail={true} alt="" id={exercise.imageId} />
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h5">{props.exercise.name}</Typography>
              <Grid rowSpacing={0.25} container>
                <Grid xs={12} item>
                  <Typography>{context.bodyZoneList.find((bz) => bz.id === props.exercise.mainBodyZoneId)?.name ?? 'N.A.'}</Typography>
                </Grid>
                <Grid xs={6} item>
                  <Typography>Weight: {props.exercise.templateConfig.weight || '-'}</Typography>
                </Grid>
                <Grid xs={6} item>
                  <Typography>Reps: {props.exercise.templateConfig.repetitions || '-'}</Typography>
                </Grid>
                {props.exercise.templateConfig.useTimer && (
                  <>
                    <Grid xs={4} item>
                      <Typography>Time: {props.exercise.templateConfig.time || '-'}</Typography>
                    </Grid>
                    <Grid xs={4} item>
                      <Typography>Init: {props.exercise.templateConfig.initTime || '-'}</Typography>
                    </Grid>
                    <Grid xs={4} item>
                      <Typography>Break: {props.exercise.templateConfig.breakTime || '-'}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

const DetailsComponent = (props: ExerciseProps & { setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const context = useContext(AppContext);
  return (
    <Grow timeout={props.timeout} in={true}>
      <Paper onClick={props.onClick} style={{ width: '100%' }} elevation={4}>
        <Grid item>
          <Grid xs={12} container>
            <Grid item sx={{ margin: 2 }} xs={12}>
              <Typography variant="h3">{props.exercise.name}</Typography>
            </Grid>
            <Grid item container style={{ margin: 10, justifyContent: 'center' }} md={3} xs={12}>
              <Box>
                <ImageFromServer
                  sx={{
                    objectFit: 'contain',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    borderRadius: '2.5%',
                    top: '50%',
                    left: '50%',
                    width: 'auto',
                    height: 'auto',
                  }}
                  thumbnail={true}
                  alt=""
                  id={props.exercise.imageId}
                />
              </Box>
            </Grid>

            <Grid item style={{ margin: 10 }} md={8}>
              <Stack>
                <Grid container>
                  <Grid xs={12} item>
                    <Typography variant="h6">Zone: {context.bodyZoneList.find((bz) => bz.id === props.exercise.mainBodyZoneId)?.name ?? 'Not selected'}</Typography>
                  </Grid>
                </Grid>
                <Box sx={{ my: 1 }}>
                  <Typography sx={{ marginBottom: 0.5 }} variant="h6">
                    Muscles:
                  </Typography>
                  <TagIntensityList muscleIntensities={props.exercise.muscleIntensities} />
                </Box>
                <Box sx={{ my: 1, minHeight: 50 }}>
                  <Typography variant="h6">Description:</Typography>
                  <Typography>{props.exercise.description !== '' ? props.exercise.description : '-'}</Typography>
                </Box>
                <Grid sx={{ my: 1 }} container>
                  <Grid xs={2} item>
                    <Typography variant="h6">Weight: {props.exercise.templateConfig.weight || '-'}</Typography>
                  </Grid>
                  <Grid xs={2} item>
                    <Typography variant="h6">Repetitions: {props.exercise.templateConfig.repetitions || '-'}</Typography>
                  </Grid>
                  <Grid xs={8}></Grid>
                  {props.exercise.templateConfig.useTimer && (
                    <>
                      <Grid sx={{ mt: 1 }} xs={4} item>
                        <Typography variant="h6">Time: {props.exercise.templateConfig.time || '-'}</Typography>
                      </Grid>
                      <Grid sx={{ mt: 1 }} xs={4} item>
                        <Typography variant="h6">Init Time: {props.exercise.templateConfig.initTime || '-'}</Typography>
                      </Grid>
                      <Grid sx={{ mt: 1 }} xs={4} item>
                        <Typography variant="h6">Break Time: {props.exercise.templateConfig.breakTime || '-'}</Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid sx={{ mt: 1 }} container>
                  <Grid xs={3} item>
                    <Typography variant="h6">Alternate sides: {props.exercise.multiLimb ? 'Yes' : 'No'}</Typography>
                  </Grid>
                </Grid>
                <Stack>
                  {props.exercise.exerciseLinks.map((el) => (
                    <Box>
                      <Link onClick={(e) => e.stopPropagation()} href={el.uri}>
                        {el.title}
                      </Link>
                    </Box>
                  ))}
                </Stack>
              </Stack>
            </Grid>
            <Stack sx={{ my: 3, mx: 3, width: '100%', alignItems: 'flex-end' }}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  props.setShowDeleteModal(true);
                }}
              >
                Delete
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Grow>
  );
};
