import { Alert } from '@mui/material';

export const ErrorComponent = (props: { errors: Array<string> }) => {
  return props.errors.length > 0 ? (
    <Alert severity="error">
      Errors:
      <ul>
        {props.errors.map((e) => (
          <li>{e}</li>
        ))}
      </ul>
    </Alert>
  ) : (
    <></>
  );
};
