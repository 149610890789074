import { CreateUser, LocalUser, Role, User } from '../models/user';
import { fetchWithReauth, logout } from './auth';

export const setLocalUser = (user: LocalUser) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const deleteLocalUser = () => {
  localStorage.removeItem('user');
};

export const getLocalUser = (): LocalUser | null => {
  const localUserstring = localStorage.getItem('user');
  if (localUserstring) return JSON.parse(localUserstring);
  return null;
  //throw new Error('User not set.');
};

export const isLoggedIn = () => {
  return getLocalUser() !== null;
};

export const isAdminUser = () => {
  //Try/catch, fix legacy login mobile issue
  try {
    return getLocalUser()?.roles.includes(Role.Admin);
  } catch (e) {
    logout();
    return false;
  }
};

export const getUserData = async (): Promise<User> => {
  const response = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/user/auth/me`, {
    method: 'GET',
    headers: { 'content-type': 'application/json' },
  });

  return await response.json();
};

export const createUser = async (createUser: CreateUser): Promise<User> => {
  const response = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/user`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(createUser),
  });

  return await response.json();
};

export const listUsers = async (): Promise<Array<User>> => {
  const response = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/user`, {
    method: 'GET',
  });

  return await response.json();
};

export const deleteAccount = async (): Promise<void> => {
  const response = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/user/delete-account/`, { method: 'DELETE' });
};

export const deleteUser = async (userId: string): Promise<void> => {
  const response = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/user/${userId}`, { method: 'DELETE' });
};

export const forgotEmail = async (emailAddress: string): Promise<void> => {
  const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/user/forgot-password/${emailAddress}`);
};

export const getUserById = async (id: string): Promise<User> => {
  const response = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/user/${id}`, {
    method: 'GET',
  });

  return await response.json();
};
