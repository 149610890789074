import { useEffect, useState } from 'react';
import { BodyZone } from '../models/body-zone';
import { getBodyZoneList } from '../api/body-zone';

export function useBodyZoneList(): [Array<BodyZone>, () => void] {
  const [bodyZones, setBodyZones] = useState<Array<BodyZone>>([]);

  const loadBodyZones = async () => {
    const bz = await getBodyZoneList();
    setBodyZones(bz);
  };

  useEffect(() => {
    loadBodyZones();
  }, []);

  return [bodyZones, loadBodyZones];
}
