import { FileResponse } from '../models/fileResponse';
import { fetchWithReauth } from './auth';

export const uploadFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/file/upload`, {
    method: 'POST',
    body: formData,
  });
  const fileId = (await response.json()).fileId;
  return fileId;
};

export async function getFile(fileId: string): Promise<FileResponse | Response> {
  const response = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/file/${fileId}`, {
    method: 'GET',
  });
  if (response.ok)
    return {
      blob: await response.blob(),
      mimeType: response.headers.get('content-type')?.toString(),
    };
  else return response;
}
