import { Box, Stack, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

export function VerificationComplete(props: any) {
  let { result } = useParams<any>();
  return (
    <Stack spacing={2} style={{ alignItems: 'center' }}>
      <Typography variant="h4" style={{ color: '#fefae0' }}>
        Verification {result === 'true' ? 'Complete' : 'Failed'}
      </Typography>
      <Typography>
        <Link style={{ color: '#fefae0' }} to={'/login'}>
          Go to the Login Page
        </Link>
      </Typography>
    </Stack>
  );
}
