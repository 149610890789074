import { Card, CardActionsProps, CardContent, Grow, Stack, Typography } from '@mui/material';
import { Set } from '../models/set';
import { Key } from '@mui/icons-material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImageFromServer } from './ImageFromServer';

export const SetCard = (props: { set: Set; isSetCreator: boolean; isDefault?: boolean; children?: ReactElement<CardActionsProps>; timeout: number }) => {
  const { set, isSetCreator: setCreator, isDefault, children } = props;

  const navigate = useNavigate();

  return (
    <Grow in={true} timeout={props.timeout}>
      <Card sx={{ minHeight: 150, maxWidth: 450, borderWidth: isDefault ? 1 : 0, borderColor: 'primary.dark', borderStyle: 'solid' }} className="h-100">
        <CardContent onClick={() => navigate(`/set-viewer/${set.id}`)}>
          <ImageFromServer sx={{ maxWidth: 100, maxHeight: 100 }} id={set.imageId} alt="" thumbnail={false} />
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {set?.name}
          </Typography>
          <Stack direction='row'>
          {setCreator && <Key sx={{mr:1}} />}
          <Typography sx={{fontSize: 14, justifyContent:'center', alignContent:'center'}} color="text.secondary" gutterBottom>
           Created by {!setCreator ?set?.creatorName : 'You'}
          </Typography>
          </Stack>
          <Typography variant="body1">{set?.description || 'No Description'}</Typography>
          <Typography variant="caption">Last updated at {set.modificationTimestamp ? set.modificationTimestamp : 'unknown'}</Typography>
        </CardContent>
        {children}
      </Card>
    </Grow>
  );
};
