import { Select, MenuItem } from '@mui/material';
import { useContext } from 'react';
import { AppContext } from '../../App';
import { Exercise } from '../../models/exercise';
import { Intensity } from '../../models/muscle-intensity';

export const SimpleTagConfiguration = (props: { exercise: Exercise; setExercise: (exercise: Exercise) => void }) => {
  const { bodyZoneList, muscleTagMap } = useContext(AppContext);

  return (
    <Select sx={{ my: 1 }} value={props.exercise.mainBodyZoneId ?? ''} displayEmpty>
      <MenuItem value="">Not Selected</MenuItem>
      {bodyZoneList?.map((z, i) => (
        <MenuItem
          key={i}
          // selected={z.id === props.exercise?.mainBodyZoneId}
          value={z.id}
          onClick={(e) => {
            if (props.exercise) {
              console.log(z.zoneTagIds);
              props.setExercise({ ...props.exercise, mainBodyZoneId: z.id, muscleIntensities: z.zoneTagIds.map((tid) => ({ muscleId: tid, intensity: Intensity.medium })) });
            }
          }}
        >
          {z.name}
        </MenuItem>
      ))}
    </Select>
  );
};
