import { useEffect, useState } from 'react';
import { getFile, uploadFile } from '../api/file';
import { getThemeByName, getThemeNameList, updateTheme } from '../api/theme';
import { FileResponse } from '../models/fileResponse';
import { SoundType } from '../models/soundType';
import { SoundMap, Theme } from '../models/theme';

const enumToArray = () => Object.keys(SoundType).filter((key) => isNaN(Number(key)));

interface SoundMapProps {
  soundIndex: number;
  soundMap?: SoundMap;
  fileSelectionComplete: (soundFile: File) => void;
}

const SoundSelector = (props: SoundMapProps) => {
  const [soundFile, setSoundFile] = useState<File | null>();
  const [soundFileUri, setSoundFileUri] = useState<string>('');

  let r = new FileReader();
  useEffect(() => {
    const load = async () => {
      if (props.soundMap?.id !== undefined) {
        const response = await getFile(props.soundMap.soundId);
        if (response.blob && typeof response.blob === 'object') {
          r.readAsDataURL((response as FileResponse).blob);
        }
      }
    };
    load();
  }, []);

  r.onloadend = (e) => {
    const result = r.result?.toString();
    if (result) {
      setSoundFileUri(result);
    }
  };

  return (
    <div>
      <div style={{ display: 'inline-block' }}>ID: {props.soundIndex}</div>
      <input
        className="exercise-image-file-input"
        type="file"
        onChange={(e) => {
          let f = e.target.files ? e.target.files[0] : '';
          if (e.target.files) {
            setSoundFile(e.target.files[0]);
          }
        }}
      />

      {soundFile !== undefined && soundFile !== null ? (
        <div style={{ background: 'blue' }}>
          {SoundType[props.soundIndex]}
          <button
            onClick={() => {
              props.fileSelectionComplete(soundFile);
            }}
          >
            Upload
          </button>
          <audio src={URL.createObjectURL(soundFile)} controls></audio>
        </div>
      ) : (
        <div style={{ background: 'red' }}>
          {SoundType[props.soundIndex]}
          <audio src={soundFileUri} controls></audio>
        </div>
      )}
    </div>
  );
};

export function Themes() {
  const [themeList, setThemeList] = useState<Array<string>>();
  const [themeName, setThemeName] = useState<string>('');
  const [renderTheme, setRenderTheme] = useState<Theme>();

  useEffect(() => {
    async function initialize() {
      if (!themeList) {
        const l = await getThemeNameList();
        setThemeList(l);
      }
      if (themeName !== '') {
        console.log('Getting theme from server');
        const t = await getThemeByName(themeName);
        setRenderTheme(t);
        setThemeName('');
      }
    }
    initialize();
  }, [themeList, themeName]);

  return (
    <div className="App">
      <h2 style={{ color: 'white' }}>Themes</h2>
      <div className="top-bar"></div>
      {themeList?.map((tn) => (
        <div
          key={tn}
          style={{ color: 'white' }}
          onClick={() => {
            setThemeName(tn);
          }}
        >
          {tn}
        </div>
      ))}
      <div>&nbsp;</div>
      {renderTheme ? <ThemeBlock renderTheme={renderTheme} setRenderTheme={(t) => setRenderTheme(t)} /> : null}
    </div>
  );
}

function ThemeBlock(props: { renderTheme: Theme; setRenderTheme: (t: Theme) => void }) {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div>
          <div style={{ color: 'white' }}>Theme Name: {props.renderTheme.name}</div>
          <div>&nbsp;</div>
          <button
            onClick={async () => {
              let t = props.renderTheme;
              updateTheme(t);
            }}
          >
            Save
          </button>
        </div>
        <>
          <div>
            <ColorSelector
              title="Background"
              color={props.renderTheme.css.background}
              setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, background: color } })}
            />
            <ColorSelector
              title="ActiveTintColor"
              color={props.renderTheme.css.activeTintColor}
              setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, activeTintColor: color } })}
            />
            <ColorSelector
              title="HeaderColor"
              color={props.renderTheme.css.headerColor}
              setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, headerColor: color } })}
            />
            <ColorSelector
              title="HeaderTintColor"
              color={props.renderTheme.css.headerTintColor}
              setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, headerTintColor: color } })}
            />
            <ColorSelector
              title="TabIconSelected"
              color={props.renderTheme.css.tabIconSelected}
              setColor={(color) => props.setRenderTheme({ ...props.renderTheme!, css: { ...props.renderTheme!.css, tabIconSelected: color } })}
            />
            <ColorSelector
              title="TabIconDefault"
              color={props.renderTheme.css.tabIconDefault}
              setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, tabIconDefault: color } })}
            />
            <ColorSelector
              title="Tint"
              color={props.renderTheme.css.tint}
              setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, tint: color } })}
            />
            <ColorSelector
              title="Text"
              color={props.renderTheme.css.text}
              setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, text: color } })}
            />
            <ColorSelector
              title="LargeInfoTextColor"
              color={props.renderTheme.css.largeInfoTextColor}
              setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, largeInfoTextColor: color } })}
            />
          </div>
        </>
        <>
          <div>
            <h4 style={{ color: 'white' }}>Sounds:</h4>
            {enumToArray().map((s, i) => (
              <SoundSelector
                key={s}
                soundIndex={i}
                soundMap={props.renderTheme.soundMap[i]}
                fileSelectionComplete={async (file) => {
                  const fileId: string = await uploadFile(file);
                  let soundMap: SoundMap = { id: i, soundId: fileId };
                  const soundArray = [...props.renderTheme.soundMap];
                  soundArray[i] = soundMap;
                  props.setRenderTheme({ ...props.renderTheme, soundMap: soundArray });
                }}
              />
            ))}
          </div>
        </>
      </div>
    </>
  );
}

function ColorSelector(props: { title: string; color: string; setColor: (s: string) => void }) {
  return (
    <div style={{ width: '200px', textAlign: 'right', color: 'white' }}>
      {props.title}: <input type="color" id={props.color} value={props.color} onChange={(e) => props.setColor(e.target.value)}></input>
    </div>
  );
}
