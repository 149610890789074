import { Box } from '@mui/material';
import { forwardRef, useState } from 'react';

export const ImageFromFile = forwardRef((props: any, ref) => {
  const [imageBase64, setImageBase64] = useState<string>();
  let r = new FileReader();
  r.readAsDataURL(props.image);
  r.onloadend = (e) => {
    setImageBase64(r.result?.toString());
  };
  return <Box ref={ref} {...props} src={imageBase64} component={'img'} />;
});
